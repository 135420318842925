<template>
    <v-app :class="{ 'theme--dark': $route.meta.dark }">
        <preloader />

        <div>
            <div v-if="!disabled_registration_mode || (disabled_registration_mode && $auth.user())">
                <!-- Layout component -->
                <component
                    v-if="isRouterLoaded"
                    :is="currentLayout"
                    component=""
                >
                    <transition name="fade" mode="out-in">
                        <template v-if="keyRouteView" >
                            <router-view :key="keyRouteView"/>
                        </template>
                        <template v-else >
                            <router-view/>
                        </template>
                    </transition>
                </component>
            </div>

            <login-modal-window    :active="loginWindow" :redirectOnAuth="redirectOnAuth"/>
            <register-modal-window :active="registerWindow"/>
            <forgot-modal-window   :active="forgotWindow"/>
            <the-wallet v-if="$auth.ready()" :active="walletWindow"/>
            <verify-email-modal-window :active="verifyEmailWindow" @close="$router.push('/')" />
            <verify-email-before-login-modal-window :active="verifyEmailBeforeLoginWindow" />
            <verify-email-success-modal-window :active="verifyEmailSuccessWindow" />

            <low-bonus-balance
                v-model="lowBonusBalanceModal"
                :message="lowBonusBalanceMessage"
                :rules="lowBonusBalanceRules"
            />
            <profile-drawer v-if="authUser" :model="profileDrawer" />

            <user-dashboard-mobile v-if="authUser" v-model="userDashboardMobile"/>
            <error-modal-window :active="errorWindow"/>

            <bet-timeout-modal-window :active="betTimeoutWindow"/>

            <v-snackbar v-model="toast.show" :timeout="toast.timeout" :color="toast.color" top right>
                {{ $t(toast.message) }}

                <v-btn v-if="toast.timeout === 0" color="white" text @click="toast.show = false">{{
                        $t('common.close')
                    }}
                </v-btn>
            </v-snackbar>

            <crypto-icons />

        </div>
    </v-app>
</template>

<script>
import Pusher from "pusher-js";
import {mapActions, mapState} from 'vuex'
import config from './configs'

// Layouts
import Echo from "laravel-echo";
import defaultLayout from './layouts/DefaultLayout'
import simpleLayout from './layouts/SimpleLayout'
import affiliateLayout from './layouts/AffiliateLayout.vue'
import authLayout from './layouts/AuthLayout'
import errorLayout from './layouts/ErrorLayout'
import dashboardLayout from './layouts/DashboardLayout'
import Login from "./components/modals/Login";
import Error from "./components/modals/Error";
import Register from "./components/modals/Register";
import Forgot from "./components/modals/Forgot";
import TheWallet from "@/components/modals/TheWallet/TheWallet.vue"
import VerifyEmail from "./components/modals/VerifyEmail";

import Translation from "./models/Translation";
import LowBonusBalance from "./components/modals/LowBonusBalance";
import Preloader from "./components/Preloader";
import RegistrationLayout from "./layouts/RegistrationLayout";
import {cancelBonusAtWithdrawal} from "./helpers/confirm-alert";
import infoAlert from "./helpers/info-alert";
import Bonus from "./models/Bonus";
import breaks from "./mixins/breaks";
import BetTimeout from "./components/modals/BetTimeout";
import TagEvent from "./models/TagEvent";
import CustomTextField from "@/components/ui/CustomTextField.vue";
import UserDashboardMobile from "@/components/modals/UserDashboardMobile.vue";
import userDashboardMobile from "@/components/modals/UserDashboardMobile.vue";
import ProfileDrawer from "@/components/navigation/ProfileDrawer.vue";
import CryptoIcons from "@/components/common/CryptoIcons";
import Swal from "sweetalert2";
import VerifyEmailNotification from "@/components/modals/VerifyEmailBeforeLogin.vue";
import VerifyEmailSuccess from "@/components/modals/VerifyEmailSuccess.vue";

export default {
    components: {
        CryptoIcons,
        ProfileDrawer,
        UserDashboardMobile,
        LowBonusBalance,
        defaultLayout,
        simpleLayout,
        affiliateLayout,
        authLayout,
        errorLayout,
        dashboardLayout,
        RegistrationLayout,
        Preloader,
        'login-modal-window': Login,
        'error-modal-window': Error,
        'register-modal-window': Register,
        'forgot-modal-window': Forgot,
        'verify-email-modal-window': VerifyEmail,
        'verify-email-before-login-modal-window': VerifyEmailNotification,
        'verify-email-success-modal-window': VerifyEmailSuccess,
        'bet-timeout-modal-window': BetTimeout,
        CustomTextField,

        TheWallet,
    },
    mixins: [breaks],
    computed: {
        keyRouteView() {
            if(this.$route.meta.noKeyForRouteView) {
                return false;
            }
            return this.$route.fullPath;
        },
        userDashboardMobile() {
            return userDashboardMobile
        },
        ...mapState('user', ['lastLoginUsername']),
        ...mapState('app', ['toast', 'translationSettings'],),
        ...mapState({
            isPreloader: state => state.app.preloader.active,
            betTimeoutWindow: state => state.app.windows.betTimeout,
            loginWindow: state => state.app.windows.login,
            walletWindow: state => state.app.windows.wallet,
            redirectOnAuth: state => state.app.redirectOnAuth,
            errorWindow: state => state.app.windows.error,
            registerWindow: state => state.app.windows.register,
            forgotWindow: state => state.app.windows.forgot,
            profileDrawer: state => state.app.profileDrawer,
            verifyEmailWindow: state => state.app.windows.verifyEmail,
            verifyEmailBeforeLoginWindow: state => state.app.windows.verifyEmailBeforeLogin,
            verifyEmailSuccessWindow: state => state.app.windows.verifyEmailSuccess,
            userDashboardMobile: state => state.app.windows.userDashboardMobile,
            userAmount: state => state.user.amount,
            userBonusAmount: state => state.user.bonus_amount,
            userTotalAmount: state => state.user.total_amount,
            login: state => state.user.login,
            top_winners: state => state.app.brandSettings.top_winners ? state.app.brandSettings.top_winners : null,
            disabled_registration_mode: state => state.app.brandSettings.disabled_registration_mode ? state.app.brandSettings.disabled_registration_mode : false,
            gameLinkBeforeLogin: state => state.app.gameLinkBeforeLogin,
            default_language: state => state.app.brandSettings.default_language,
            verify_email: state => state.app.brandSettings.verify_email,
            redirect_to_deposit: state => state.app.brandSettings.redirect_to_deposit,
            seo_meta_tags: state => state.app.brandSettings.seo_meta_tags,
            currentLang: state => state.user.current_lang,
            country: state => state.app.country,
            tags: state => state.app.tags,
            loadedGame: state => state.user.loadedGame,
            crypto_print: state => state.user.crypto.is_print,
            userCryptoCurrency: state => state.user.crypto.crypto_currency,
        }),
        isRouterLoaded: function () {
            return this.$route.name !== null;
        },
        currentLayout: function () {
            let layout = this.$route.meta.layout || 'default'

            return layout + 'Layout'
        },
        currentLocale() {
            return this.translationSettings.find((i) => i.code === this.$i18n.locale)
        },
        availableLocales() {
            return this.translationSettings.filter((i) => i.code !== this.$i18n.locale)
        },
        authUser() {
            return !!this.$auth.user()
        },
        siteTitle() {
            let title = '';
            if (this.seo_meta_tags) {
                for(const [path, tag] of Object.entries(this.seo_meta_tags)) {
                    if (this.$route.path.startsWith(path) && tag?.title) {
                        title = tag.title.content?.[this.currentLang] ?? Object.values(tag.title.content)[0]
                        break;
                    }
                }

                if (!title) {
                    title = Object.values(this.seo_meta_tags?.defaults?.title?.content ?? {})[0]
                }
            }
            return title;
        },
        siteMeta() {
            let meta = [];
            if (this.seo_meta_tags) {
                for(const [path, tag] of Object.entries(this.seo_meta_tags)) {
                    if (this.$route.path.startsWith(path)) {
                        meta = Object.values(tag).map(item => {
                            return {...item,
                                'content': item.content?.[this.currentLang] ?? Object.values(item.content)[0]
                            }
                        })
                        break;
                    }
                }

                if (!meta.length) {
                    meta = Object.values(this.seo_meta_tags?.defaults ?? {}).map(item => {
                        return {...item,
                            'content': item.content?.[this.currentLang] ?? Object.values(item.content)[0]
                        }
                    })
                }
            }
            return meta;
        }
    },
    data() {
        return {
            banners: [],
            tagEvent: new TagEvent(),
            lowBonusBalanceModal: false,
            lowBonusBalanceMessage: null,
            lowBonusBalanceRules: [],
            Echo: null,
            // userDashboardMobile: true,

        }
    },
    methods: {
        ...mapActions('translatable', ['updateTranslatable']),
        ...mapActions('app', ['makeActiveWindow', 'showPreloader', 'hidePreloader']),
        ...mapActions({
            setBalance: 'user/setBalance',
            storeGameCryptoBalance: 'user/setCryptoBalance',
            setGameCryptoBalance: 'user/setGameCryptoBalance',
            setGameBalance: 'user/setGameBalance',
            setBonusTarget: 'user/setBonusTarget',
            setBonusDeposit: 'user/setBonusDeposit',
            setBonus: 'user/setBonus',
            setWithdrawalWithActiveBonus: 'user/setWithdrawalWithActiveBonus',
        }),
        async getLocale(locale) {
            let dynamic = await Translation.find(locale);
            await this.updateTranslatable(locale);
            this.$i18n.setLocaleMessage(this.$i18n.locale, {...this.$i18n.getLocaleMessage(this.$i18n.locale), ...dynamic})
        },
        //getLocale
        authEcho() {
            this.Echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.MIX_PUSHER_APP_KEY,
                cluster: process.env.MIX_PUSHER_APP_CLUSTER,
                encrypted: true,
                forceTLS: true,
                logToConsole: true,
                auth: {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('auth_token_default')}`,
                    },
                },
            })
            this.Echo.private('site-' + process.env.MIX_BACKOFFICE_DOMAIN_ID)
                .listen('TagEvent',  (e) => {
                    this.tagEvent.update()
                })

            this.Echo.private('user-' + this.$auth.user().api_user_id)
                .listen('BetTimeoutEvent', (e) => {
                    this.makeActiveWindow({ active: true, type: 'betTimeout'})
                })
                .listen('TagProcessedEvent', (e) => {
                    this.tagEvent.update()
                })
                .listen('UserUnverifiedEmailEvent', (e) => {
                    this.makeActiveWindow({ active: true, type: 'verifyEmail'})
                })
                .listen('UserFirstDepositEvent', (e) => {
                    this.$store.dispatch('app/getTags');
                    this.$auth.fetch()
                })
                .listen('WithdrawalWithActiveBonus', async (e) => {
                    let res = await cancelBonusAtWithdrawal(e.message);
                    if (!res) {
                        return false
                    } else {
                        new Bonus(this.$auth.user().active_bonus).delete()
                    }
                })
                .listen('BonusStrictModeNotificationEvent', (e) => {
                    let res = infoAlert('Info', e.message, this.$t("common.ok"));
                })
                .listen('BonusMoneyOpenBetNotificationEvent', (e) => {
                    infoAlert('Info', this.$t('bonus.bonusMoneyOpenBetNotificationMessage'), this.$t("common.ok"));
                })
                .listen('LowBonusBalance', (e) => {
                    this.lowBonusBalanceModal = true;
                    this.lowBonusBalanceRules = e.rules ? e.rules : [];
                    this.lowBonusBalanceMessage = e.message ? e.message : null;
                })
                .listen('UpdateCryptoBalance', async (e) => {
                    axios.get("/api/show-balance").then(res => {
                        this.storeGameCryptoBalance({
                            balances: res.data,
                            userCryptoCurrency: this.userCryptoCurrency
                        })
                    })
                })
                .listen('BalanceUpdated', async (e) => {
                    this.$emit('changeBalance', e)
                    this.setBalance({ amount: e.amount / 100, amount_bonus: e.amount_bonus / 100 })
                    this.$emit('changeBalance', e)

                    if (!e.amount && !e.amount_bonus && this.redirect_to_deposit) {
                        this.$router.push('/user/deposit')
                    }
                    axios.post("/api/update-balance", {amount: e.amount, amount_bonus: e.amount_bonus})
                })
                .listen('GameBalanceUpdate', (e) => {
                    this.setGameBalance({ amount: e.amount / 100, amount_bonus: e.amount_bonus / 100 })
                })
                .listen('GameCryptoBalanceUpdate', (e) => {
                    this.setGameCryptoBalance(e)
                })
                .listen('UpdateUserBonusEvent', (e) => {
                    this.$auth.fetch()
                    this.setBonusDeposit({});
                    this.setBonus(e)
                })
                .listen('BonusTargetEvent', (e) => {
                    if (this.$auth.user().active_bonus.type === 'cashback') {
                        this.$auth.user().active_bonus.potential_cashback = e.target
                    }
                    this.setBonusTarget({
                        bonus_target: e.target
                    });
                })
                .listen('BonusDepositEvent', (e) => {
                    this.$auth.fetch().then(res => {
                        if (res.active_bonus && res.active_bonus.type === 'deposit') {
                            this.setBonusDeposit({
                                name: e.bonusName,
                                id: e.bonusId,
                            });
                        }
                    })
                })
                .listen('DisableUser', (e) => {
                    this.$auth.logout({});
                })
                .listen('BonusErrorEvent', (e) => {
                    let data = {};
                    switch (e.errorCode) {
                        case 601:
                            data.type = e.data.is_multiple ? this.$t('bonus.type.multiple') : this.$t('bonus.type.single')
                            data.odds_settings = e.data.is_multiple ? e.data.multiple : e.data.single
                            data.odds = e.data.odds
                            break;
                        case 602:
                            data.amount = e.data.amount
                            break;
                    }
                    Swal.fire({
                        title: this.$t('bonus.error.title'),
                        text: this.$t(e.errorText, data),
                        type: 'error',
                    });
                })
        },
        guestEcho() {
            this.Echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.MIX_PUSHER_APP_KEY,
                cluster: process.env.MIX_PUSHER_APP_CLUSTER,
                encrypted: true,
                forceTLS: true,
                authEndpoint: '/guest/broadcast/endpoint'
            })

            this.Echo.private('site-' + process.env.MIX_BACKOFFICE_DOMAIN_ID)
                .listen('TagEvent', (e) => this.tagEvent.update())
        },
        onlineChanel() {
            const isAuth = this.$auth.user()
            if (isAuth && !this.Echo) {
                this.authEcho()
                this.Echo.join('online')
            } else if (!isAuth && !this.Echo) {
                this.guestEcho()
                this.Echo.join('online');
            }
        },
        pusherInit() {
            if (this.$auth.user()) {
                this.setBalance({
                    amount: this.$auth.user().amount,
                    amount_bonus: this.$auth.user().amount_bonus
                });

                this.setBonusTarget({
                    bonus_target: this.$auth.user().bonuses && this.$auth.user().bonuses.length ? (this.$auth.user().bonuses.find(v => v.is_active) && this.$auth.user().bonuses.find(v => v.is_active).bonus_progress) : 0
                })
                const activeBonus = this.$auth.user().active_bonus
                const pendingBonus = this.$auth.user().pending_bonus
                if (pendingBonus) {
                    this.setBonus(pendingBonus)
                }

                if (activeBonus) {
                    this.setBonus(activeBonus)
                }
            }
        },
        checkRoute(to) {
            if (Array.isArray(this.tags)) {
                this.tags.map(v => {
                    if (v.settings && v.settings?.pages?.length) {
                        if (v.settings.pages.include.length && v.settings.pages.exclude.length) {
                            v.settings.pages.include.push('error-access-denied')
                            v.settings.pages.include.push('error-not-found')
                            v.settings.pages.include.push('error-unexpected')
                            v.settings.pages.include.push('auth-reset')
                            v.settings.pages.include.push('verify-email')
                            v.settings.pages.include.push('FifaWorldCup2022')
                            if (v.settings.pages.exclude.includes(to.name) || !v.settings.pages.include.includes(to.name)) {
                                this.$router.push('/error/access-denied')
                            }
                        }
                    }

                    if (v.settings && v.settings.device) {
                        if (v.settings.device === 'mobile' && !this.smAndDown) {
                            this.$router.push({name: 'error-access-denied'})
                        }
                        if (v.settings.device === 'desktop' && this.smAndDown) {
                            this.$router.push({name: 'error-access-denied'})
                        }
                    }
                    if (v.settings && v.settings.language) {
                        if (this.currentLang && v.settings.language && this.currentLang !== v.settings.language) {
                            this.$router.push({name: 'error-access-denied'})
                        }
                    }
                    if (v.settings && v.settings.country) {
                        if (this.country && v.settings.country && this.country !== v.settings.country) {
                            this.$router.push({name: 'error-access-denied'})
                        }
                    }
                })
            } else {
                if (Object.keys(this.tags).length) {
                    const pages = this.tags.settings.pages
                    const device = this.tags.settings.device
                    const language = this.tags.settings.language
                    const country = this.tags.settings.country
                    if (pages.include.length && pages.exclude.length) {
                        pages.include.push('error-access-denied')
                        pages.include.push('error-not-found')
                        pages.include.push('error-unexpected')
                        pages.include.push('auth-reset')
                        pages.include.push('verify-email')
                        pages.include.push('FifaWorldCup2022')
                        if (pages.exclude.includes(to.name) || !pages.include.includes(to.name)) {
                            this.$router.push('/error/access-denied')
                        }
                    }
                    if (device === 'mobile' && !this.smAndDown) {
                        this.$router.push({name: 'error-access-denied'})
                    }
                    if (device === 'desktop' && this.smAndDown) {
                        this.$router.push({name: 'error-access-denied'})
                    }
                    if (this.currentLang && language && this.currentLang !== language) {
                        this.$router.push({name: 'error-access-denied'})
                    }
                    if (this.country && country && this.country !== country) {
                        this.$router.push({name: 'error-access-denied'})
                    }
                }
            }
        },
        setPlatform() {
            const platform = this.isDesktop ? 'desktop' : 'mobile'
            if(this.$store.state.app.platform !== platform) {
                this.$store.dispatch('app/setPlatform', platform)
            }
        }
    },
    watch: {
        lastLoginUsername(val, oldVal) {
            if (val !== oldVal && !this.$auth.user() && this.Echo) {
                this.Echo.channel('casino-user-username-' + val)
                    .listen('LoggedUser', () => {
                        if (!this.$auth.user()) {
                            window.location.reload();
                        }
                    });
            }
        },
        $route(to, from) {
            this.$vuetify.theme.dark = to?.meta?.dark ?? config.theme.globalTheme;
            this.checkRoute(to)
            if (to.name === 'slots-game') {
                this.$store.commit('user/setLoadedGame', to.path)
            }

            if (this.$auth.user()) {
                axios.get('/api/user-activity?page=' + to.name)
            }
            if (this.siteTitle) {
                document.title = this.siteTitle;
            }
            if (this.siteMeta?.length) {
                this.siteMeta.forEach(meta => {
                    const selector = document.querySelector(`meta[name=${meta.name}]`);
                    if (selector) {
                        selector.setAttribute('content', meta.content);
                    } else {
                        const newTag = document.createElement('meta');
                        newTag.setAttribute('name', meta.name);
                        newTag.setAttribute('content', meta.content);
                        document.head.appendChild(newTag);
                    }
                })
            }
        },
        currentLang() {
            if (this.siteTitle) {
                document.title = this.siteTitle;
            }
            if (this.siteMeta?.length) {
                this.siteMeta.forEach(meta => {
                    const selector = document.querySelector(`meta[name=${meta.name}]`);
                    if (selector) {
                        selector.setAttribute('content', meta.content);
                    } else {
                        const newTag = document.createElement('meta');
                        newTag.setAttribute('name', meta.name);
                        newTag.setAttribute('content', meta.content);
                        document.head.appendChild(newTag);
                    }
                })
            }
        },
        login() {
            if (this.Echo) {
                this.Echo.disconnect()
            }
            if (this.login) {
                if (this.$auth.user()) {
                    this.authEcho()
                }
                this.Echo.join('online')
                if (this.gameLinkBeforeLogin) {
                    this.$router.push(this.gameLinkBeforeLogin)
                    this.$store.commit('app/setGameLinkBeforeLogin', '')
                }
            } else {
                this.guestEcho()
                this.Echo.join('online')
                if (this.disabled_registration_mode && !this.$auth.user()) {
                    this.makeActiveWindow({active: true, type: 'login'})
                }
            }
        },
        authUser() {
            this.$store.dispatch('app/getTags');
            this.pusherInit()
        },
        isDesktop() {
            this.setPlatform();
        }
    },
    async created() {
        this.setPlatform();
        const urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())
        if (params.affid) {
            localStorage.setItem('affid', params.affid)
        }

        if (params.cxd || params.CXD) {
            localStorage.setItem('cxd', params.cxd || params.CXD)
        }

        // in store app/_init
        await this.$store.dispatch('app/getTags');
        await this.$store.dispatch('app/getBrandSkin')
        await this.$store.dispatch('app/getBrandSettings')
        await this.$store.dispatch('app/getTranslationSettings');
        await this.$store.dispatch('app/getBanners');

        // -- in store user/_init
        try {
            const lang = window?.appGlobals?.userLanguageCode || (this.default_language ? this.default_language : this.translationSettings.length ? this.translationSettings[0].code : 'en')
            if (localStorage.getItem('lang')) {
                await this.getLocale(localStorage.getItem('lang'));
                this.$store.commit('user/setCurrentLang', localStorage.getItem('lang'))
            } else {
                await this.getLocale(lang);
                this.$store.commit('user/setCurrentLang', lang)
            }
        } catch (e) {
            console.error(e)
        }

        await this.$store.dispatch('init')

        this.onlineChanel()
        this.pusherInit()
        this.hidePreloader()

        if (this.disabled_registration_mode && !this.$auth.user()) {
            this.makeActiveWindow({active: true, type: 'login'})
        }

        if (this.top_winners) {
            await this.$store.dispatch('app/getTopWinnersList')
        }

        if (this.$route.name === 'slots-game') {
            this.$store.commit('user/setLoadedGame', this.$route.path)
        }
        await this.$store.dispatch('app/getCountry')
        this.checkRoute(this.$route)
    },
    head: {
        link: [
            ...config.icons.map((href) => ({rel: 'stylesheet', href}))
        ]
    }
}
</script>

<style scoped>

[v-cloak] {
    display: none;
}

/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

.preloader-enter-active,
.preloader-leave-active {
    transition-duration: 0.5s;
    transition-property: opacity;
    transition-timing-function: ease-in;
}

.preloader-enter,
.preloader-leave-active {
    opacity: 0;
}
</style>
