import { render, staticRenderFns } from "./BonusModalFreebet.vue?vue&type=template&id=17bdd3cc"
import script from "./BonusModalFreebet.vue?vue&type=script&lang=js"
export * from "./BonusModalFreebet.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runcloud/webapps/demo-2-jetxplatform/releases/20241211093902/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17bdd3cc')) {
      api.createRecord('17bdd3cc', component.options)
    } else {
      api.reload('17bdd3cc', component.options)
    }
    module.hot.accept("./BonusModalFreebet.vue?vue&type=template&id=17bdd3cc", function () {
      api.rerender('17bdd3cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/bonuses/BonusModalFreebet.vue"
export default component.exports