export const setCookie = (name, value, minutes = 120) => {
    const d = new Date();
    document.cookie = "tmp=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    d.setTime(d.getTime() + (minutes * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/`;
}

export const deleteCookie = (name) => {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    const expires = ";expires=" + d;
    const value = ""
    document.cookie = `${name}=${value};${expires};path=/`;
}

export const getCookie = (name) => {

    let value = document.cookie.match('(^|;)?' + name + '=([^;]*)(;|$)');
    return value ? decodeURIComponent(value[2]) : null;

}
