<template>
        <v-form class="login-toolbar" ref="form" v-model="isFormValid" lazy-validation @submit.prevent>
            <custom-text-field
                v-model="data.username"
                :rules="[rules.required]"
                :validate-on-blur="false"
                :disabled="isLoading"
                :error="errors.hasOwnProperty('username')"
                :error-messages="errors.hasOwnProperty('username') ? errors.username[0] : ''"
                name="username"
                outlined
                persistent-placeholder
                :placeholder="$t('auth.login.fields.username')"
                @keyup.enter="submit"
                @input="resetErrors"
                :no-error-icon="true"
            />

            <custom-text-field
                v-if="!comingFromAnotherSystem || confirmUser"
                v-model="data.password"
                :rules="[rules.required]"
                :type="showPassword ? 'text' : 'password'"
                :disabled="isLoading"
                :error="errors.hasOwnProperty('password')"
                :error-messages="errors.hasOwnProperty('password') ? errors.password[0] : ''"
                persistent-placeholder
                :placeholder="$t('auth.login.fields.password')"
                name="password"
                outlined
                @input="resetErrors"
                @keyup.enter="submit"
                @click:append="showPassword = !showPassword"
                :no-error-icon="true"
            >
                <template v-slot:append>
                    <div class="login-toolbar__link-forgot" @click.prevent="makeActiveWindow({ active: true, type: 'forgot'})">
                        <transition name="slide-x">
                            <span v-if="data.password.length < 4">Forgot</span>
                        </transition>
                        <span>?</span>
                    </div>
                </template>
            </custom-text-field>


        <v-btn
            :loading="isLoading"
            :disabled="isSignInDisabled"
            class="button--primary text-capitalize"
            @click="submit"
        >{{
                $t('menu.authLogin')
            }}
        </v-btn>

        </v-form>
</template>

<script>
import {mapActions, mapState} from "vuex";
import breaks from "@/mixins/breaks";
import Echo from "laravel-echo";
import CustomTextField from "@/components/ui/CustomTextField.vue";


export default {
    name: "LoginToolbar",
  components: {
    CustomTextField
  },
    props: ['active', 'redirectOnAuth'],
    mixins: [breaks],
    data() {
        return {
            showMigrateUser: false,
            comingFromAnotherSystem: false,
            confirmUser: false,
            isLoading: false,
            isSignInDisabled: false,
            isFormValid: true,
            confirmUserHash: null,
            socialProviders: [
                {
                    name: 'google',
                    icon: 'mdi-google'
                },
                {
                    name: 'facebook',
                    icon: 'mdi-facebook'
                },
                {
                    name: 'instagram',
                    icon: 'mdi-instagram'
                },
                {
                    name: 'twitter',
                    icon: 'mdi-twitter'
                },
                {
                    name: 'apple',
                    icon: 'mdi-apple'
                },
                {
                    name: 'reddit',
                    icon: 'mdi-reddit'
                },
                {
                    name: 'pinterest',
                    icon: 'mdi-pinterest'
                },
                {
                    name: 'twitch',
                    icon: 'mdi-twitch'
                },
                {
                    name: 'vkontakte',
                    icon: 'mdi-vk'
                },
                {
                    name: 'telegram',
                    icon: 'mdi-telegram'
                },
            ],
            data: {
                username: '',
                password: '',
                password_confirmation: '',
                passport: '',
                email: '',
                country: ''
            },

            qr_image: '',
            secret: '',
            google2fa: false,
            one_time_password: '',

            errors: {},
            error: null,
            showPasswordConfirmation: false,
            showPassword: false,
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t("form.required"),
                turkishPassport: (value) => {
                    const textError = "invalid"
                    if (!(/^[1-9]\d{10}$/).test(value)) {
                        return textError
                    }
                    let odds = 0
                    let evens = 0
                    let sumOf10 = 0
                    const digits = value.split('')
                    const d10 = Number(digits[9])
                    const d11 = Number(digits[10])

                    digits.forEach(function (d, index) {
                        d = Number(d);
                        if (index < 10) sumOf10 += d;
                        if (index < 9) {
                            if ((index + 1) % 2 === 0) {
                                evens += d;
                            } else {
                                odds += d;
                            }
                        }
                    })

                    if (sumOf10 % 10 !== d11 || ((odds * 7) + (evens * 9)) % 10 !== d10 || (odds * 8) % 10 !== d11) {
                        return textError
                    }

                    return true
                },
            }
        }
    },
    computed: {
        redirectTo() {
            return this.redirectOnAuth || this.$route.fullPath
        },
        user() {
            return this.$auth.user();
        },
        ...mapState({
            google2fa_enable: state => state.app.brandSettings.google2fa,
            google2fa_required: state => state.app.brandSettings.google2fa_required,
            auth_social: state => state.app.brandSettings.auth_social ? state.app.brandSettings.auth_social : false,
            redirect_after_login: state => state.app.brandSettings.redirect_after_login,
            disabled_registration_mode: state => state.app.brandSettings.disabled_registration_mode ? state.app.brandSettings.disabled_registration_mode : false
        }),
        ...mapState({
            migrateUser: state => state.app.brandSettings.migrate_user
        }),
        isTurkishCountry() {
            return typeof this.data.country === "string" ? (
                this.data.country.toLowerCase() === 'turkey'
            ) : false
        },
        passportRules() {
            const rules = [this.rules.required]

            if (this.isTurkishCountry) {
                rules.push(this.rules.turkishPassport)
            }

            return rules
        },
        activeComputed: {
            get() {
                return this.active;
            },
            set(value) {
                this.makeActiveWindow({active: value, type: 'login'});
            }
        }
    },
    watch: {
        comingFromAnotherSystem() {
            if (this.error) {
                this.error = null
            }
        },
        user(val) {
            if(val) {
                this.$store.dispatch('app/setRedirectOnAuth', '');
            }
        }
    },
    methods: {
        authProvider(provider) {
            axios.get(`/api/authorize/${provider}/redirect`).then(res => {
                if (res.data.url) {
                    window.location.href = res.data.url
                }
            })
        },
        ...mapActions('app', ['makeActiveWindow']),
        ...mapActions({
            setBalance: 'user/setBalance',
        }),
        async submit() {
            if (this.$refs.form.validate()) {
                this.isLoading = true;
                this.isSignInDisabled = true;
                if (this.comingFromAnotherSystem && !this.confirmUser) {
                    const data = {passport: this.data.passport, username: this.data.username, email: this.data.email}
                    axios.post('api/check-migrated-user', data).then(res => {
                        this.confirmUserHash = res.data.hash
                        this.data.country = res.data.country
                        this.confirmUser = true
                        this.isLoading = false
                        this.isSignInDisabled = false
                        this.error = null
                    }).catch(e => {
                        this.error = e.response.data
                        this.isLoading = false
                        this.isSignInDisabled = false
                    })
                } else if (this.confirmUser) {
                    const data = {
                        passport: this.data.passport,
                        username: this.data.username,
                        email: this.data.email,
                        user_id: this.confirmUserHash,
                        password: this.data.password,
                        password_confirmation: this.data.password_confirmation
                    }
                    axios.post('api/register-migrated-user', data).then(res => {
                        try {
                            const login_data = {
                                username: this.data.username,
                                password: this.data.password
                            }
                            this.$store.commit('user/setMigratedUser', true)
                            this.$auth.login({
                                data: login_data,
                                redirect: this.redirectTo,
                                staySignedIn: true
                            })
                            this.$store.commit('user/setLogin', true)
                            this.activeComputed = false;
                            this.isLoading = false
                            this.error = null
                        } catch (error) {
                            this.errors = error.response.data.errors;
                            this.isLoading = false;
                            this.isSignInDisabled = false;
                        }
                    }).catch(e => {
                        this.error = e.response.data
                        this.isLoading = false
                        this.isSignInDisabled = false
                    })
                } else if (this.google2fa) {
                    axios.post('/api/verify-google2fa', {
                        username: this.data.username,
                        password: this.data.password,
                        one_time_password: this.one_time_password
                    }).then(res => {
                        if (res.data === 'Accepted') {
                            this.$auth.login({
                                data: this.data,
                                redirect: this.redirectTo,
                                staySignedIn: true
                            })
                            this.$store.commit('user/setLogin', true)
                            this.activeComputed = false;
                        }
                    }).catch(error => {
                        this.errors = error.response.data.errors;
                        this.isLoading = false;
                        this.isSignInDisabled = false;
                    })
                } else if (this.google2fa_enable && !this.google2fa) {
                    try {
                        await axios.post('/api/google2fa-login', this.data).then(res => {
                            if (res.data) {
                                this.google2fa = true
                                this.qr_image = res.data.qr_image ? res.data.qr_image : null
                                this.secret = res.data.secret ? res.data.secret : null
                                this.isLoading = false;
                                this.isSignInDisabled = false;
                            } else {
                                this.$auth.login({
                                    data: this.data,
                                    redirect: this.redirectTo,
                                    staySignedIn: true
                                }).then(() => {
                                    this.$store.commit('user/setLogin', true)
                                    this.setBalance({
                                        amount: this.$auth.user().amount,
                                        amount_bonus: this.$auth.user().amount_bonus
                                    });
                                    this.$refs.form.reset()
                                    this.activeComputed = false;
                                    this.isSignInDisabled = false;
                                    this.isLoading = false;
                                }).catch((error) => {
                                    this.isLoading = false;
                                    this.isSignInDisabled = false;
                                    if (error.response.data.errors) {
                                        this.errors = error.response.data.errors;
                                    }
                                    if (error.response.data.message) {
                                        this.error = error.response.data.message
                                    }
                                })
                            }
                        })
                    } catch (error) {
                        this.errors = error.response.data.errors;
                        this.isLoading = false;
                        this.isSignInDisabled = false;
                    }
                } else {
                    try {
                        this.$auth.login({
                            data: this.data,
                            redirect: this.redirectTo,
                            staySignedIn: true
                        }).then(() => {
                            this.$store.commit('user/setLogin', true)
                            this.setBalance({
                                amount: this.$auth.user().amount,
                                amount_bonus: this.$auth.user().amount_bonus
                            });
                            this.$refs.form.reset()
                            this.activeComputed = false;
                            this.isSignInDisabled = false;
                            this.isLoading = false;
                        }).catch((error) => {
                            this.isLoading = false;
                            this.isSignInDisabled = false;
                            if (error.response.data.errors) {
                                this.errors = error.response.data.errors;
                            }
                            if (error.response.data.message) {
                                this.error = error.response.data.message
                            }
                        })
                    } catch (error) {
                        this.errors = error.response.data.errors;
                        this.isLoading = false;
                        this.isSignInDisabled = false;
                    }
                }
            }
        },
        signInProvider(provider) {

        },
        resetErrors() {
            this.errors = {};
        }
    }
}
</script>

<style lang="scss">

.login-toolbar {
    display: flex;
    align-items: center;
    gap: 8px;

    .custom-text-field {
        margin-bottom: 0 !important;
        border-radius: 4px;

        input {
            font-size: 14px !important;
            font-weight: 400 !important;
        }

        .v-input .v-input__control .v-input__slot {
            min-height: 35px !important;
        }

        .custom-text-field__messages {
            display: none;
        }

        .v-input:not(.error--text) fieldset {
            border-color: transparent;
        }

        .login-toolbar__link-forgot {
            position: absolute;
            top: 10px;
            right: 8px;
            cursor: pointer;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
        }
    }
}

.slide-x-enter-active {
    transition: all .3s ease;
}
.slide-x-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-x-enter, .slide-x-leave-to {
    transform: translateX(100px);
    opacity: 0;
}
</style>
